import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-newsletter-welcome-dialog',
    template: `
        <div class="mat-dialog-header">
            <div mat-dialog-title>Välkommen till Vinbörsen!</div>
        </div>

        <div mat-dialog-content>
            <p style="margin: 16px 0;">
                Du kommer nu få mail av oss så fort vi upptäcker nya prissänkningar.
            </p>
        </div>

        <div mat-dialog-actions>
            <button mat-flat-button color="accent" (click)="close()">
                <span>Stäng</span>
            </button>
        </div>
    `
})
export class AppSharedNewsletterWelcomeDialogComponent {
    constructor(private dialogRef: MatDialogRef<AppSharedNewsletterWelcomeDialogComponent>) {}

    close() {
        this.dialogRef.close();
    }
}
