import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Subject } from 'rxjs';

import { VbAuthBrowserFacadeService } from '@vb/auth/browser';

import { AppTrackerService } from '../../tracker/tracker.service';

@Component({
    selector: 'app-newsletter-signup-dialog',
    styleUrls: ['./signup-dialog.component.scss'],
    template: `
        <div class="newsletter-outer">
            <div class="newsletter-inner">
                <div class="newsletter-text-area-outer">
                    <div class="newsletter-text-area-inner">
                        <h2
                            style="font-size: 32px; line-height: 37px; font-weight: 200;"
                            *ngIf="title"
                        >
                            {{ title }}
                        </h2>
                        <p style="font-size: 17px; line-height: 20.4px;" *ngIf="description">
                            {{ description }}
                        </p>
                        <app-newsletter-form (completed)="close()"></app-newsletter-form>

                        <div class="newsletter-loading" *ngIf="loading$ | async">
                            <mat-spinner diameter="40"></mat-spinner>
                        </div>
                    </div>
                </div>
                <div style="" class="newsletter-image-container">
                    <div class="newsletter-image">
                        <ui-image
                            src="https://vinborsen-se.imgix.net/static/newsletter-signup-image.jpg"
                            [lazyload]="true"
                            [sizes]="{
                                xs: [400, 400],
                                sm: [600, 600],
                                md: [800, 600],
                                lg: [1000, 1000]
                            }"
                        ></ui-image>
                    </div>
                </div>
                <button
                    mat-icon-button
                    (click)="close()"
                    style=""
                    class="newsletter-close-button-outer"
                >
                    <div style="" class="newsletter-close-button-inner">
                        <mat-icon>close</mat-icon>
                    </div>
                </button>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None
})
export class AppSharedNewsletterDialogComponent implements OnInit, OnDestroy {
    private _destroy$ = new Subject<void>();

    @Input() title: string | boolean = 'Få alla nya fynd direkt i mejlen.';
    @Input() description: string | boolean =
        'Bli medlem så får du alla tips om nya prissänkta och prisvärda produkter direkt till din mejl.';

    @Input() force = false;
    @Input() color: 'green' | undefined;

    @Output() readonly completed = new EventEmitter<void>();

    @HostBinding('attr.color') get attrBgColor() {
        return this.color || null;
    }

    loading$ = this._authFacade.loading$;

    constructor(
        private _dialogRef: MatDialogRef<AppSharedNewsletterDialogComponent>,
        private _authFacade: VbAuthBrowserFacadeService,
        private _tracker: AppTrackerService
    ) {}

    ngOnInit() {
        this._tracker.track('User Registration Opened');
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    close() {
        this._dialogRef.close();
    }
}
