import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CookieService } from 'ngx-cookie-service';

import { AppHelperService } from '../../app-helper.service';
import { RootFacade } from '../../state/root/root.facade';
import { SignupStatus } from '../../state/root/root.reducer';
import { AppSharedNewsletterDialogComponent } from './dialog/signup-dialog.component';
import { AppSharedNewsletterWelcomeDialogComponent } from './dialog/welcome-dialog.component';

// Local constants, move to environment config?
const TEASER_PROMPTED_COOKIE_NAME = '_vb-teaser-prompted';
const REGISTER_PROMPTED_COOKIE_NAME = '_vb-register-prompted';

@Injectable({ providedIn: 'root' })
export class AppSharedNewsletterService {
    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private _dialog: MatDialog,
        private _appHelper: AppHelperService,
        private _rootFacade: RootFacade,
        private _cookieService: CookieService
    ) {}

    isTeaserPrompted() {
        if (isPlatformServer(this.platformId)) {
            return true;
        }

        return this._cookieService.check(TEASER_PROMPTED_COOKIE_NAME);
    }

    isRegistrationPrompted() {
        if (isPlatformServer(this.platformId)) {
            return true;
        }

        return this._cookieService.check(REGISTER_PROMPTED_COOKIE_NAME);
    }

    openWelcomeDialog() {
        this._dialog.open(AppSharedNewsletterWelcomeDialogComponent, {
            width: '736px',
            maxWidth: 'calc(100vw - 40px)',
            hasBackdrop: true
        });
    }

    openRegisterDialog() {
        this._dialog.open(AppSharedNewsletterDialogComponent, {
            width: '916px',
            maxWidth: 'calc(100vw - 40px)',
            hasBackdrop: true
        });

        this._rootFacade.setSignupStatus(SignupStatus.PROMPTED);

        this.setRegisterCookie();
    }

    setRegisterCookie() {
        const _location = this._appHelper.getCurrentLocation();

        this._cookieService.set(
            REGISTER_PROMPTED_COOKIE_NAME,
            'v1',
            30,
            '/',
            _location.hostname,
            _location.protocol === 'https',
            'Lax'
        );
    }
}
