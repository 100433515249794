import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { VbAuthBrowserFacadeService } from '@vb/auth/browser';

import { RootFacade } from '../../../state/root/root.facade';
import { SignupStatus } from '../../../state/root/root.reducer';
import { AppTrackerService } from '../../tracker/tracker.service';
import { AppSharedNewsletterService } from '../newsletter.service';

@Component({
    selector: 'app-newsletter-form',
    styleUrls: ['./newsletter-form.component.scss'],
    template: `
        <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="app-newsletter-form__group">
                <mat-form-field appearance="outline" style="padding: 0 !important;">
                    <mat-label>Fyll i din e-mail-adress</mat-label>
                    <input
                        style="padding: 0 !important;"
                        type="email"
                        matInput
                        formControlName="email"
                        cdkFocusInitial
                        email
                        required
                    />
                </mat-form-field>
                <button mat-flat-button type="submit" color="accent" size="large">Skicka</button>
            </div>

            <mat-checkbox wrap required requiredTrue formControlName="accept" value="1">
                Jag intygar att jag är 20 år eller äldre och accepterar Vinbörsens
                <a [routerLink]="['/om-oss', 'privacy-policy']">användaravtal</a>.
            </mat-checkbox>
        </form>
    `,
    encapsulation: ViewEncapsulation.None
})
export class AppSharedNewsletterFormComponent implements OnInit, OnDestroy {
    private _destroy$ = new Subject<void>();

    @Input() color: 'green' | undefined;

    @Output() readonly completed = new EventEmitter<void>();

    @HostBinding('attr.color') get attrBgColor() {
        return this.color || null;
    }

    @HostBinding('class.app-newsletter-form') public baseclass = true;

    form = this._fb.group({
        email: [''],
        accept: ['', Validators.requiredTrue]
    });

    constructor(
        private _fb: FormBuilder,
        private _rootFacade: RootFacade,
        private _authFacade: VbAuthBrowserFacadeService,
        private _tracker: AppTrackerService,
        private _newsletterService: AppSharedNewsletterService
    ) {}

    ngOnInit() {
        this.form
            .get('email')
            .valueChanges.pipe(takeUntil(this._destroy$), take(1))
            .subscribe(() => {
                this._rootFacade.setSignupStatus(SignupStatus.STARTED);

                this._tracker.track('User Registration Started');
            });
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    submit() {
        if (!this.form.valid) {
            this.form.get('accept').markAsTouched();
            return;
        }

        // Update Signup status
        this._rootFacade.setSignupStatus(SignupStatus.PENDING);

        const email = this.form.get('email').value;

        this._authFacade.register(email);

        // Wiat for successful registration event
        this._authFacade.getNextUserAction('registerSuccess').subscribe(() => {
            this.completed.next();

            this._newsletterService.openWelcomeDialog();

            this._rootFacade.setSignupStatus(SignupStatus.COMPLETED);
        });
    }
}
